.contStart {
    position: fixed;
    top: 0;
    left:50%;
    transform: translate(-50%, 0);
    height: 100%;
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    background-color: rgba(0, 66, 37, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10005;
}
.windowStart {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 300px;
    /* height: 60px; */
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b)
}
.windowStart2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    width: 250px;
    margin-left: 70px;
    /* height: 60px; */
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b)
}
.windowStart2s {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    width: 250px;
    margin-right: 70px;
    /* height: 60px; */
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b)
}
.windowStart3 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    width: 250px;
    margin-left: 70px;
    /* height: 60px; */
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b)
}
.windowStart3s {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 200px; */
    width: 300px;
    /* height: 60px; */
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b)
}
.windowStart4 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 300px;
    /* height: 60px; */
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b)
}
.windowStart5 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 300px;
    /* height: 60px; */
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b)
}
.pStart {
    color: white;
    margin: auto;
    font-size: 20px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    line-height: 1;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
}
.contSkip {
    position: absolute;
    bottom: 60px;
    right: 10px;
    background-color: #FFD700;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
}
.skip {
    font-size: 22px;
    margin: 0;
    color: white;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
}
@media screen and (max-height: 840px) {
    .contSkip {
        top:20px;
        bottom: auto;
    }
    .skip {
        font-size: 16px;
    }
}
@media screen and (max-width: 389px) {
    .windowStart {
        margin-bottom: 200px;
    }
    .windowStart2 {
        margin-top: 0px;
    }
    .windowStart2s {
        margin-top: 50px;
        width: 230px;
    }
    .windowStart3 {
        margin-top: 50px;
        width: 200px;
    }
    .windowStart4 {
        margin-top: 50px;
        width: 200px;
        left: 40px;
    }
    .windowStart5 {
        margin-top: 50px;
        /* width: 200px;
        left: 40px; */
    }

}
@media screen and (max-width: 400px) {
    .windowStart3s {
        margin-top: 70px;
    }
}