.contPoints {
    width: 100%;
    max-width: 450px;
    /* padding: 20px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}
.eachPoint {
    width: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    border: 2px solid white;
    border-radius: 5px;
    margin-left: 5px;
    height: 40px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #FFD700;

}
.pointImg {
    margin: 0;
    width: 50px;
    height: 40px;
}
.pPoints {
    margin: 0;
    font-size: 10px;
    font-weight: bold;
    color: white;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
    text-shadow: 0 0 1px #000;
}
