.under {
    position: fixed;
    bottom: 0px;
    left: 0px;
    height: 70%;
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    /* background-image: url('./image//money.jpeg'); */
    background-color: rgb(26, 88, 8);
    background-size: cover;
    opacity: 1;
}
.contInfo {
    position: fixed;
    top:0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    height: 100%;
    z-index: 4;
    box-sizing: border-box;
}
.contHeadInfo {
    text-align: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 2px solid white;
    background-color: rgb(19%, 47%, 11%, 1);
    width: 93%;
}
.contH{
    color: #FFD700;
    font-size: 38px;
    text-shadow: 0 0 2px black;
    margin: 0px;
    font-family:'Courier New', Courier, monospace;
}
.eachInfo{
    width: 93%;
    margin: auto;
    background: linear-gradient(to bottom, #ffdb58, #31791b);
    border: 2px solid white;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.infoImg {
    width: 70px;
    height: 70px;
    pointer-events: none;
}
.textInfo {
    color: white;
    font-size: 16px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    margin: 0px;
    margin-bottom: 10px;
    line-height: 1;
    text-shadow: 0 0 1px black;
}
.closeInfo {
    position: fixed;
    top: 11px;
    right: 3px;
    width: 65px;
    height: 65px;

}
.blockDown {
    width: 100%;
    box-sizing: border-box;
    margin-right: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFD700;
    /* border: 1px solid black; */
    border-radius: 10px;
    padding: 2px ;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9);
}

.imgDown {
    width: 20px;
    height: 20px;
    pointer-events: none;
}
.pDown{
    color: white;
    font-size: 18px;
    margin: 0px;
    margin-top: 2px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
}
@media screen and (max-width: 400px) {
    .pDown {
        font-size: 16px;
    }
}