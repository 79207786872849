.contModalShop {
    position: fixed;
    top: 0px;
    height: 100%;
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    background-color: rgba(0, 66, 37, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}
.windowModalShop {
    width: 250px;
    /* height: 1500px; */
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 0px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    border: 1px solid #004225;
    position: relative;
    /* border: 10px solid transparent;
    border-image: url("./image/money.jpeg") 20 round; */
    background-image: url('./image//money.jpeg');
    background-size: cover;
    border: 2px solid white;
}
.shopHead {
    width: 270px;
    background-color: #FFD700;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    margin-bottom: 20px;
    text-align: center;
    padding: 10px;
}
.twoBlocks {
    display: flex;
    flex-direction: row;

}
.block {
    position: relative;
    width: 120px;
    height: 65px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    margin: 5px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    cursor: pointer;
    border: 2px solid white;
    background: linear-gradient(to bottom, rgba(255, 215, 0, 0.9), rgb(19%, 47%, 11%, 0.9));
}
.contTextShop{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    bottom: 2px;
    right: 2px;
    border-radius: 8px;
    padding: 2px;
    background-color: #FFD700;
    width: 55px;
    border:1px solid rgb(65, 64, 64)
}
.imgCoin {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border:1px solid rgb(65, 64, 64)
}
.imgText {
    margin: 0px;
    color: white;
    font-weight: bold;
    text-shadow: 0 0 2px rgb(7, 7, 7);
    font-size: 12px;
    font-family:'Courier New', Courier, monospace;
}
.pShop {
    font-family:'Courier New', Courier, monospace;
    color: white;
    font-weight: bold;
    font-size: 32px;
    margin: 0;
    text-shadow: 0 0 2px rgb(7, 7, 7);
}
.unBlock {
    position: relative;
    width: 120px;
    height: 65px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    margin: 5px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    cursor: pointer;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b);
    /* opacity: 0.6; */
}
.coverBlock {
    position: absolute;
    right: -2px;
    width: 120px;
    height: 65px;
    border-radius: 8px;
    background-color: rgba(54, 122, 31, 0.7);
    z-index: 2;
    border-radius: 8px;
    border: 2px rgb(189, 189, 189) solid;
}
.imgShop {
    width: 55px;
    height: 55px;
    margin-left: 5px;
    pointer-events: none
}
.imgShop2 {
    position: absolute;
    width: 30px;
    height: 30px;
    pointer-events: none;
    left: 75px;
    top:9px;
    opacity: 0.7;
}
