.contTable {
    position: fixed;
    top:0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    background-color: rgba(0, 66, 37, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}
.windowTable {
    position: relative;
    width: 75%;
    max-height: 90vh;
    /* height: 200px; */
    /* height: 1500px; */
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 20px;
    padding-top: 0px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    border: 1px solid #004225;
    position: relative;
    /* border: 10px solid transparent;
    border-image: url("./image/money.jpeg") 20 round; */
    background-image: url('./image//money.jpeg');
    background-size: cover;
    border: 2px solid white;
}
.closeTable {
    position: absolute;
    top:-25px;
    right: -25px;
    width: 60px;
    height: 60px;
}
.contHTable{
    width: 100%;
    text-align: center;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    background-color:  #FFD700;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.hTable {
    margin: 0;
    color: white;
    font-family:'Courier New', Courier, monospace;
    text-shadow: 0 0 2px black;
    font-size: 28px;
    line-height: 0.9;
}
.blockLiders {
    width: 100%;
    max-height: 70vh;
    overflow: auto;
}
.contBlockTable {
    width: 98%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 5px;
    background-color: #31791b;
    border-width: 2px; /* Толщина границы */
    border-style: solid; /* Стиль границы - двойная */
    border-color: white; /* Цвета границы (внешний и внутренний) */
    border-radius: 25px;

}
.contSoundTable {
    /* border-image-source: url('./image//grass2.JPG');
    border-image-slice: 30;
    border-image-repeat: round; */
    background-color: #31791b;
    padding: 10px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.tableImg {
    width: 50px;
    height: 50px;
    pointer-events: none;
    border-radius: 15px;
}
.descTable {
    /* background-color: tomato; */

}
.pTable {
    color: white;
    font-size: 22px;
    font-family:'Courier New', Courier, monospace;
    margin: 0px;
    margin-left: 10px;
    font-weight: bold;
}
.medalTable{
    width: 25px;
    height: 25px;
}
.pTableDown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:left;
    color: #FFD700;
    font-size: 20px;
    font-family:'Courier New', Courier, monospace;
    margin: 0px;
    margin-left: 10px;
    font-weight: bold;
}