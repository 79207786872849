.containerJail {
  margin-top: 3px;
    width: 112px;
    height: 112px;
    border-radius: 5px;
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 1); */
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerLowyer {
  width: 90px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #FFD700;
  border: 2px solid white;
}
.lowyerP {
  margin: 0;
  font-weight: bold;
  color: white;
  text-shadow: 0 0 1px #000;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
}
.numberJail {
    font-size: 102px;
    color: grey;
    font-weight: bold;
}
/* .contJail {
  cursor: pointer;
  width: 65px;
  height: 65px;
} */
.contJailAnime {
  width: 65px;
  height: 65px;
  animation-name: shake3; /* Имя анимации из ключевых фреймов */
  animation-duration: 0.8s; /* Продолжительность анимации */
  animation-timing-function: ease; /* Функция времени (плавное изменение) */
  animation-iteration-count: infinite; /* Количество повторений (бесконечно) */
}


@keyframes shake3 {
  0%, 100% {
      transform: rotate(0deg);
    scale: 1;
    }
    25% {
      transform: rotate(17deg);
    }
    50% {
      transform: rotate(-17deg);
      scale: 1.1;
    }
    75% {
      transform: rotate(17deg);

    }
}
@keyframes biger {
  0%, 100% {
  scale: 1;
  }
  50% {
    scale: 1.1;
  }
}