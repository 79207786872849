.contLoading {
    position: fixed;
    top:0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #31791b;
    z-index: 100000000;
    
}
.contPLoad {
    width: 330px;
}
.hLoading {
    font-size: 52px;
    text-shadow: -5px 0px 5px black;
    color: white;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
}
.contStart1 {
    position: fixed;
    top:0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    background-color: rgb(19%, 47%, 11%, 1);
    background-image: url('./image/startImg.JPG');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1001;
}
.contHead1 {
  /* background-color: tomato; */
  /* width: 95%; */
  text-align: center;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* background: linear-gradient(to bottom, #ffdb58, #31791b); */
  display: flex;
  flex-direction: row;
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
  /* border: 2px solid white; */
  border-bottom: none;
  margin-top: 50px;
  /* margin-bottom: 120px; */
}
.logo {
  /* padding-top: 40px; */
  width: 55px;
  height: 55px;
  margin-bottom: 2px;
  /* padding-left: 2px;
  padding-right: 2px; */
  pointer-events: none;

}
.contHead {
    /* width: 95%; */
    text-align: center;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    /* padding-top: 10px;
    padding-bottom: 10px; */
    /* background: linear-gradient(to bottom, #ffdb58, #31791b); */
    display: flex;
    flex-direction: column;
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
    /* border: 2px solid white; */
    /* border-bottom: none; */
    margin-top: 50px;
    /* margin-bottom: 120px; */
}
.contAll {
    position: relative;
    display: flex;
    width: 95%;
    box-sizing: border-box;
    margin-bottom: 20%;
    /* background: linear-gradient(to bottom, #ffdb58, #31791b); */
    /* background-image: url('./image//topWall.JPG');
    background-size: cover; */
    flex-direction: column;
    align-items: center;
    justify-content: top;
    height: 400px;
    /* border-top: 2px solid white;
    border-bottom: 2px solid white; */
    /* border: 2px solid white; */
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6); */
}

.name {
    /* position: absolute;
    top: 70px; */
    display: flex;
    align-items: center; /* Выравнивание элементов по вертикали */
    margin: 0;
    margin-bottom: 5px;
    color: white;
    font-size: 62px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    text-shadow: 
    -5px 0px 5px black, /* Тень */
    -1px -1px 0 black,  
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black; /* Обводка */
    animation: fall 1.5s ease-in-out;
    animation-delay: 5s;
    

}
.contPlay {
    position: absolute;
    text-align: center;
    top: -30%;
    right: 18%px;
    /* background: linear-gradient(to bottom, #ffdb58, #31791b); */
    padding: 20px;
    /* border: 2px solid white; */
    border-right: none;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.imgStart {
    position: absolute;
    bottom: 10%;
    left: 0px;
    width: 300px;
    height: 300px;
    /* margin-top: 100px;
    margin-right: 140px; */
    opacity: 0.9;
}
.slogan {
    /* position: absolute;
    top: 140px; */
    margin: 0;
    color: white;
    font-size: 22px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    text-shadow: 
    -3px 0px 3px black, /* Тень */
    -1px -1px 0 black,  
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black; /* Обводка */
    animation: fall 1.5s ease-in-out;
    animation-delay: 5s;
}
.btnPlay {
    /* position: absolute; */
    /* top: -30%;
    right: 18%px; */
    width: 120px;
    height: 120px;
    margin-bottom: 100px;
    margin-right: 2px;
    /* margin-left: 18px; */
    animation: fall 1.5s ease-in-out;
    animation-delay: 4.9s;
    border: 1px solid rgb(0, 0, 0);
    /* margin-top: 50%; */
    /* margin-bottom: 50px; */

    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 35px;
    border: 2px solid white;
    border-radius: 5px;
    background-color: #FFD700;
    margin-bottom: 20px; */
    box-shadow: -1px -1px 4px rgb(0, 0, 0);
    border-radius: 50%;

}
.activ {
    animation-delay: 4.9s;
    animation-name: opacity; /* Имя анимации из ключевых фреймов */
    animation-duration: 5s; /* Продолжительность анимации */
    animation-timing-function: ease; /* Функция времени (плавное изменение) */
    animation-iteration-count: 1; /* Количество повторений (бесконечно) */
}
.sloganPlay {
    /* position: absolute;
    top: 140px; */
    margin: 0;
    color: white;
    font-size: 24px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    text-shadow: 0px 0px 5px black;
}
.playP {
    color: white;
    font-family:'Courier New', Courier, monospace;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0px 0px 3px black;
}

.btn {
    /* margin-top: 100px; */

    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid rgb(0, 0, 0);
    border-radius: 10px;
    background-color: rgb(19%, 47%, 11%, 1);
    /* opacity: 0.9; */
    /* background: linear-gradient(to bottom, #ffdb58, #31791b); */
    box-shadow: -1px -1px 4px rgba(0, 0, 0, 1);
    /* overflow: hidden;  */
    animation: fall 1.5s ease-in-out;
    animation-delay: 4.9s;
}
.blockStart {
    width: 300px; /* Установите нужную ширину блока */
    height: 200px; /* Установите нужную высоту блока */
    background: linear-gradient(135deg, #FFD700, #31791b);
    position: relative; /* Для правильного позиционирования блика */
    overflow: hidden; /* Чтобы блик не выходил за пределы блока */
    border-radius: 10px; /* Закругленные углы (опционально) */
  }
  
  /* Псевдоэлемент для блика */
  .btn::before {
    content: '';
    position: absolute;
    top: 50%; /* Положение пятна по вертикали, можно регулировать */
    left: 50%; /* Положение пятна по горизонтали, можно регулировать */
    width: 80%; /* Размер пятна света, можно регулировать */
    height: 80%; /* Размер пятна света, можно регулировать */
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0) 70%
    );
    transform: translate(-30%, -30%); /* Смещение для правильного позиционирования */
    opacity: 0.7;
    pointer-events: none;
    border-radius: inherit;
  }
  
.testBtn {
    position: relative;
    /* margin-top: 200px; */
    /* padding-left: 20px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 4px solid rgb(255, 251, 251);
    /* background-color: rgb(19%, 47%, 11%, 1); */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
    /* background: linear-gradient(to bottom, #ffdb58, #31791b); */
    /* border: 2px solid white; */
    animation: fall 1.5s ease-in-out;
    animation-delay: 4.9s;
}
.testBtn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.7; 
    pointer-events: none; 
    border-radius: inherit; 
  }
.iconBtn {
  width: 40px;
  height: 40px;
}
.fallCoin {
  position: absolute;
  top: -10px;
  left: 5%;
  width: 30px;
  height: 30px;
  animation: fall1 5s linear infinite 1s, spin 2s linear infinite 1s, fade 5s linear infinite 1s;
}
.fallCoin1 {
  position: absolute;
  top: -10px;
  left: 20%;
  width: 30px;
  height: 30px;
  animation: fall1 5s linear infinite 2s, spin 2s linear infinite 2s, fade 5s linear infinite 2s;
}
.fallCoin2 {
  position: absolute;
  top: -10px;
  left: 40%;
  width: 30px;
  height: 30px;
  animation: fall1 5s linear infinite 3s, spin 2s linear infinite 3s, fade 5s linear infinite 3s;
}
.fallCoin3 {
  position: absolute;
  top: -10px;
  left: 60%;
  width: 30px;
  height: 30px;
  animation: fall1 5s linear infinite 4s, spin 2s linear infinite 4s, fade 5s linear infinite 4s;
}
.fallCoin4 {
  position: absolute;
  top: -10px;
  left: 80%;
  width: 30px;
  height: 30px;
  animation: fall1 5s linear infinite 5s, spin 2s linear infinite 5s, fade 5s linear infinite 5s;
}
.fallCoin5 {
  position: absolute;
  top: -10px;
  left: 87%;
  width: 30px;
  height: 30px;
  animation: fall1 5s linear infinite 1.5s, spin 2s linear infinite 1.5s, fade 5s linear infinite 1.5s;
}
.textUp{
  position: absolute;
  bottom: -30px;
  color: #FFD700;
  text-shadow: 
  -5px 0px 5px black, /* Тень */
  -1px -1px 0 black,  
  1px -1px 0 black,
  -1px 1px 0 black,
  1px 1px 0 black; /* Обводка */
  font-family:'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 10px;
}

@keyframes fall1 {
  0% {
      top: -10px;
      /* left: calc(50% - 25px); */
  }
  100% {
      top: 100vh;
      /* left: calc(50% - 25px); */
  }
}

@keyframes spin {
  0% {
      transform: rotateY(0deg);
  }
  100% {
      transform: rotateY(360deg);
  }
}

@keyframes fade {
  0% {
      opacity: 1;
  }
  70% {
    opacity: 1;
  } 
  100% {
      opacity: 0;
  }
}

@keyframes fall {
    0% {
      scale: 0.1;
    }
    100% {
        scale: 1;
      }

  }
  @keyframes bottom {
    0% {
      top: 1000px;
    }
    100% {
        top: 600px;
      }

  }
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    50%{
        opacity: 0;
    }
    100% {
        opacity: 1;
      }

  }
  @media screen and (max-width: 374px) {
    .hLoading {
     font-size: 46px;
    }
    .contPLoad {
        width: 300px;
    }

  }
  @media screen and (max-width: 400px) {
    .btn {
      width: 50px;
      height: 50px;
    }
    .name {
      font-size: 52px;
    }
    .iconBtn {
      width: 35px;
      height: 35px;
    }
  }