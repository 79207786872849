.contHint {
    position: absolute;
    top:-150px;
    width: 240px;
    height: 100px;
    border-radius: 15px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b);
    z-index: 3;
    
}
.imgHint1{
    position: absolute;
    top:10px;
    left:20px;
    width: 40px;
    height: 40px;
    pointer-events: none;
}
.imgHint2{
    position: absolute;
    top:30px;
    left:60px;
    width: 40px;
    height: 40px;
    pointer-events: none;
}
.imgHint3{
    position: absolute;
    top:50px;
    left:20px;
    width: 40px;
    height: 40px;
    pointer-events: none;
}
.imgHint4{
    position: absolute;
    top:15px;
    left:160px;
    width: 60px;
    height: 60px;
    pointer-events: none;
}
.imgHint5{
    position: absolute;
    top:25px;
    left:105px;
    width: 50px;
    height: 50px;
    pointer-events: none;
    opacity: 0.8;
}
.hintLock1 {
    position: absolute;
    top:28px;
    left:15px;
    width: 42px;
    height: 42px;
    pointer-events: none;
}
.hintPlus {
    position: absolute;
    top:35px;
    left:60px;
    width: 35px;
    height: 35px;
    pointer-events: none;
}
.hintLock2{
    position: absolute;
    top:26px;
    left:93px;
    width: 47px;
    height: 47px;
    pointer-events: none;
}
.hintLockRight{
    position: absolute;
    top:35px;
    left:138px;
    width: 35px;
    height: 35px;
    pointer-events: none;
}
.hintLock3 {
    position: absolute;
    top:28px;
    left:180px;
    width: 45px;
    height: 45px;
    pointer-events: none;
    border-radius: 5px;
}
.hintLock4 {
    position: absolute;
    top:28px;
    left:180px;
    width: 45px;
    height: 45px;
    pointer-events: none;
    background-color: rgb(19%, 47%, 11%, 0.7);
    z-index: 6;
    border-radius: 5px;
}

