.contFinish {
    position: fixed;
    top: 0;
    left:50%;
    transform: translate(-50%, 0);
    height: 100%;
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    background-color: rgba(0, 66, 37, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10005;
}
.windowFinish {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    /* height: 60px; */
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b)
}
.textFinish {
    color: white;
    margin: auto;
    font-size: 20px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    line-height: 1;
    margin-bottom: 20px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
}
.btnFinish {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:  #FFD700;
    border: 2px solid white;
    padding: 10px;
    border-radius: 15px;
    width: 280px;
    height: 20px;
}
.btnFinish:hover {
    opacity: 0.6;
}
.btnFinish p {
    color: white;
    margin: 0px;
    font-size: 24px;
    font-family:'Courier New', Courier, monospace;
    font-weight: bold;
    text-shadow: 0 0 2px rgba(0, 0, 0, 1);
}
.closeSteps {
    position: absolute;
    top:-22px;
    right: -22px;
    width: 60px;
    height: 60px;
}