.contCont {
  width: 100%;
  height: 100%;
  background-color: gray;
  position: fixed;

}

.fieldCont{
  /* position: fixed; */
  width: 100%;
  max-width: 450px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  align-items: center;
  /* background-color: #D8C3A5; */
  min-height: 100vh;
  margin: auto;
  padding-top: 115px;
  box-sizing: border-box;
  user-select: none;
}
.fieldUnder {
  position: fixed;
  bottom: 0px;
  height: 100%;
  width: 100%;
  max-width: 450px;
  min-width: 320px;
  z-index: -2;
  background-color: rgb(80, 121, 69);
  /* background-image: url('./image//money.jpeg'); */
  background-size: cover;
  opacity: 1;
}
.contScorePoint {
  width: 100%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.headNum2 {
  border-radius: 3px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9);
  border: #8E8D8A solid 0.5px;
  width: 85px;
  height: 85px;
  /* margin: auto; */
  /* margin-top: 20px;
  margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.headNumAnime {
  border-radius: 3px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9);
  border: #8E8D8A solid 0.5px;
  width: 85px;
  height: 85px;
  /* margin: auto;
  margin-top: 20px;
  margin-bottom: 20px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cubeButton2 {
    /* margin-bottom: 20px;
    margin-top: 20px; */
    width: 85px;
    height: 85px;
    font-size: 26px;
    cursor: pointer;
    border: none;
    color: #E98074;
    /* text-shadow: 0.2px 0.2px 1px rgba(0, 0, 0, 0.6); */
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    font-family:'Courier New', Courier, monospace;
    font-weight: bolder;
}
.cubeButton2:hover {
    opacity: 0.7;
}
.test {
  position: relative;
  /* background-image: url('./image/grass1.JPG'); */
  background-color: grey;
  background-size: 60px 60px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 96%;
}
.grid-container2 {
  width: 100%;
  height: 100%;
  max-width: 450px;
  min-width: 320px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 3px;
  /* padding: 15px; */
  /* margin-bottom: 20px; */
  box-sizing: border-box;
  /* box-shadow: 0px 0px 3px rgb(0, 0, 0);  */

  border: 5px solid rgb(19%, 47%, 11%, 0.3);
  /* border-top: 5px solid rgba(255, 215, 0, 0.3);
  border-bottom: 5px solid rgb(19%, 47%, 11%, 0.3); */
  /* border: 5px solid transparent; */
  /* border-image: url('./image/grass1.png') round 100; */
  /* border-image: url("./image/grass.png") 10 round; */
  /* background-image: url('./image/grass.png');
  background-size: 80px 80px; */
  border-radius: 5px;
  background-color: rgba(28, 121, 56, 0.7);
  /* background-image: url('./image/grass3.png');
  background-size: cover; */

  user-select: none;
}

.grid-item2 {
  position: relative;
  transform-origin: bottom;
    width: 100%;
    height: 100%;
    min-width: 45px;
    min-height: 45px;
    aspect-ratio: 1 / 1; /* Соотношение сторон 1:1 */
    box-sizing: border-box;
    /* background-color: rgba(255, 215, 0, 0.3); */
    /* border: 1px solid #8E8D8A; */
    /* box-shadow: 0px 0px 2px rgb(19%, 47%, 11%, 1); */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* border-radius: 5px; */
    color: black;
    font-family:'Courier New', Courier, monospace;
    /* background-image: url('./image/grass1.png');
    background-size: cover; */
    /* text-shadow: 0px 0px 0.5px rgba(0, 0, 0, 1); */
    border-radius: 10px;
    background: linear-gradient(to bottom, rgba(255, 215, 0, 0.3), rgba(64, 133, 45, 0.3));
    /* border: 4px solid rgb(19%, 47%, 11%, 1); */
    /* opacity: 0.7; */
    /* animation-name: grass2;  */
    animation-duration: 30s; /* Продолжительность анимации */
    animation-timing-function: ease; /* Функция времени (плавное изменение) */
    animation-iteration-count: infinite; /* Количество повторений (бесконечно) */
    
}

.imgNumberAnime {
  /* width: 65px;
  height: 65px; */
  animation-name: shake2; /* Имя анимации из ключевых фреймов */
  animation-duration: 0.8s; /* Продолжительность анимации */
  animation-timing-function: ease; /* Функция времени (плавное изменение) */
  animation-iteration-count: infinite; /* Количество повторений (бесконечно) */
  z-index: 1;
}
.imgLowyer {
  width: 50px;
  height: 60px;
}
.imgLowyerAnime {
  width: 50px;
  height: 60px;
  animation-name: biger; /* Имя анимации из ключевых фреймов */
  animation-duration: 0.8s; /* Продолжительность анимации */
  animation-timing-function: ease; /* Функция времени (плавное изменение) */
  animation-iteration-count: infinite; /* Количество повторений (бесконечно) */
}
.contDrag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 90px;
  height: 90px;
  border-radius: 15px;
  /* border: 2px solid #6b7f96; */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
  /* border: 1px solid #FFD700 */
  /* background-image: url('./image/plato.jpeg');
  background-size: cover; */
  border: 2px solid white;
}
.contShop{
  /* width: 150px;
  height: 90px;
  border-radius: 15px;
  border: 2px solid #63c4e7;
  background-color: rgba(255, 255, 255, 0.4); */
  width: 140px;
  height: 90px;
  margin-top: 14px;
  position: relative;
  border-radius: 15px;
  background: linear-gradient(to bottom, #ffdb58, #31791b);
  border: 2px solid white;
  border-radius: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
  z-index: 0;
}
.imgShoping {
  margin-top: 2px;
  width: 140px;
  height: 90px;
  border-radius: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
  border: 2px solid white;
  background: linear-gradient(135deg, #ffdb58, #31791b);
}

.shopP{
  position: absolute;
  top:7px;
  left: 22px;
  /* top:50% */
  color: white;
  font-weight: bold;
  font-size: 24px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
  /* text-shadow: 0 0 3px #FFD700; */
}
.contBottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  height: 50px;
  background-color: black;
  z-index: 3;
}

@keyframes shake2 {
  0%, 100% {
    transform: rotate(0deg);
  scale: 1;
  }
  25% {
    transform: rotate(17deg);
  }
  50% {
    transform: rotate(-17deg);
    /* scale: 1.1; */
  }
  75% {
    transform: rotate(17deg);

  }
}
@keyframes biger {
  0%, 100% {
  scale: 1;
  }
  50% {
    scale: 1.2;
  }
}

@media screen and (max-height: 800px) {
  .fieldCont{
    padding-top: 55px;
    
  }
  .contBottom {
    height: 0px;
  }

}
@keyframes grass2 {
  0%, 100% {
    transform: rotateX(0deg);
  }
  25% {
    transform: rotateX(27deg);

  }
  50% {
    transform: rotateX(-37deg);

  }
  75% {
    transform: rotateX(37deg);

  }
}


