.contIntro {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    background-color: rgba(0, 66, 37, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10005;
}
.windowIntro {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 300px;
    /* height: 60px; */
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    border: 2px solid white;
    background: linear-gradient(to bottom, #ffdb58, #31791b)
}
.pIntro {
    color: white;
    margin: auto;
    font-size: 16px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    line-height: 1;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
}
.imgIntro {
    width: 80px;
    height: 80px;
    margin-left: 10px;
    pointer-events: none;
    /* border-bottom: 2px solid white; */
}
.introClose {
    position: absolute;
    width: 45px;
    height: 45px;
    top:-17px;
    right: -17px;

}
.btnShare {
    position: absolute;
    bottom: -55px;
    left:35%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid white;
    border-radius: 10px;

    background: linear-gradient(to bottom, #ffdb58, #31791b);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    
}
.imgShare {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-bottom: 2px;
}
.textShare {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 14px;
    margin: 0px;
    margin-top: 2px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    text-shadow: 0 0 2px black;
}

.imgBon {
    width: 15px;
    height: 15px;
    margin-left: 4px;
    margin-bottom: 2px;
}




@media screen and (max-width: 400px) {
    .windowIntro {
        margin-bottom: 60px;
    }
}