.contAnime{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 40px;
    /* z-index: 1; */
    animation: moveUpAndFadeOut 2s forwards;
}
.contAnimeScore{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top:70px;
  left:70px;
  /* z-index: 1; */
  animation: moveUpAndFadeIn 2s forwards;
}
.imgAnime {
    width: 20px;
    height: 20px;
}
.imgAnimeScore {
  width: 20px;
  height: 20px;
}
.pAnime {
    font-size: 18px;
    margin: 0;
    color: #FFD700;
    font-weight: bold;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
}
.pAnimeScore {
  font-size: 18px;
  margin: 0;
  color: white;
  font-weight: bold;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
}
@keyframes moveUpAndFadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-70px);
    }
  }
  @keyframes moveUpAndFadeIn {
    0% {
      opacity: 1;
      transform: translateY(-10px);
    }
    100% {
      opacity: 0;
      transform: translateY(30px);
    }
  }
  
  @media screen and (max-height: 800px) {
  .contAnimeScore{
    top:0px;
    z-index: 10000000;
    animation: moveUpAndFadeIn4 2s forwards;
  }
}
@keyframes moveUpAndFadeIn4 {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}