.contMenuDem {
    position: fixed;
    top:0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
    width: 100%;
    max-width: 450px;
    min-width: 350px;
    background-color: rgba(0, 66, 37, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}
.windowMenuDem {
    position: relative;
    width: 70%;
    /* height: 200px; */
    /* height: 1500px; */
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 20px;
    padding-top: 0px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    border: 1px solid #004225;
    position: relative;
    /* border: 10px solid transparent;
    border-image: url("./image/money.jpeg") 20 round; */
    background-image: url('./image//money.jpeg');
    background-size: cover;
    border: 2px solid white;
}
.closeMenu {
    position: absolute;
    top:-20px;
    right: -20px;
    width: 60px;
    height: 60px;
}
.contHMenu{
    width: 100%;
    text-align: center;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    background-color:  #FFD700;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;

}
.hMenu {
    margin: 0;
    color: white;
    font-family:'Courier New', Courier, monospace;
    text-shadow: 0 0 2px black;
}
.contBlock {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 10px;
    background-color: #31791b;
    border-width: 2px; /* Толщина границы */
    border-style: solid; /* Стиль границы - двойная */
    border-color: white; /* Цвета границы (внешний и внутренний) */
    border-radius: 25px;
}
.contSound {
    /* border-image-source: url('./image//grass2.JPG');
    border-image-slice: 30;
    border-image-repeat: round; */
    background-color: #31791b;
    padding: 10px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

}
.soundImg {
    width: 50px;
    height: 50px;
    pointer-events: none;
}
.pSound {
    color: white;
    font-size: 26px;
    font-family:'Courier New', Courier, monospace;
    margin: 0px;
    margin-left: 10px;
    font-weight: bold;
}
.bonusCont {
    position: absolute;
    top: 24px;
    left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px;
    padding: 2px ;
    padding-left: 4.5px;
    padding-right: 4.9px;
    background-color: #FFD700;
    border-left: 1px solid white;
    border-right: 1px solid white;
    /* border-top: 2px solid rgb(255, 255, 255);
    border-bottom: 2px solid rgb(255, 255, 255); */
    
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6); */
    rotate: -90deg;
    opacity: 1;
}
.bonusText {
    color: white;
    font-size: 14px;
    margin: 0px;
    margin-top: 2px;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    text-shadow: 0 0 2px black;
}
.bonusImg {
    width: 14px;
    height: 14px;
    pointer-events: none;
    margin-left: 5px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
    border-radius: 50%;

}
.soundImgClose {
    position: absolute;
    left: 10px;
    top:-5px;
    width: 50px;
    height: 80px;
}
@media screen and (max-width: 395px) {
    .pSound {
        font-size: 24px;
    }
}
@media screen and (max-width: 370px) {
    .pSound {
        font-size: 22px;
    }
}

