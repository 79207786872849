.contScore {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding-top: 50px; */
    width: 100%;
    max-width: 450px;
    box-sizing: border-box;
}
.eachScore {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 130px;
    /* padding: 3px; */
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6); */
    border-radius: 5px;
    /* border: 2px solid white; */
    position: relative;
}
.contImg{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #ffdb58, #31791b);
    width: 35px;
    height: 35px;
    border: 2px solid white;
    border-radius: 50%;
    z-index: 1;
}
.imgScore {
    width: 30px;
    height: 30px;
    pointer-events: none;
}
.contP{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #ffdb58, #31791b);
    border: 2px solid white;
    border-radius: 5px;
    width: 105px;
    height: 25px;
    position: absolute;
    right: 0;
}
.pScore {
    margin: 0px;
    font-weight: bold;
    color: white;
    margin-left: 10px;
}
@media screen and (max-width: 400px) {
    .pScore {
        margin-left: 30px;
    }
}